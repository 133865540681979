<template>
    <v-container fluid class="transparent">
        <v-progress-linear v-if="carriers.length === 0" indeterminate />
        <v-row>
            <v-col v-for="carrier in carriers" :key="carrier.ID" lg="3" md="4" sm="6" cols="12">
                <v-card elevation="0" height="100%" class="pa-5" color="transparent">
                    <v-img class="carrier-logo" :aspect-ratio="1/1" height="auto" width="100%" :src="imageUrl(carrier)" contain style="background-color:transparent;"></v-img>
                    <v-card-title class="pb-0 text-left mx-0" style="padding:0;">
                        <strong>{{ carrier.CarrierName }}</strong>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div v-if="carrier.AgentLoginURL" class="px-4">
                        <a @click.stop="clicked(carrier.AgentLoginURL)">
                            Agent Login
                        </a>
                    </div>
                    <div v-if="carrier.CarrierURL" class="px-4">
                        <a @click.stop="clicked(carrier.CarrierURL)">
                            Website
                        </a>
                    </div>
                    <div v-if="carrier.CarrierEAppURL" class="px-4">
                        <a @click="clicked(carrier.CarrierEAppURL)" target="_blank">E-App</a>
                    </div>
                    <div v-if="carrier.AgentPhone" class="px-4">Phone: <a :href="`tel:${carrier.AgentPhone}`">{{ formatPhoneNumber(carrier.AgentPhone) }}</a></div>
                    <div v-if="carrier.Hours" class="px-4">Hours: {{ carrier.Hours }}</div>
                    <v-card-actions class="pt-8">
                        <div class="pl-9" style="position:absolute; left:0; bottom:0; padding:20px; text-align:left; width:100%">
                            <v-btn class="px-4" color="q_green_2" dark v-if="carrier.CarrierCMSURL" @click="clicked(carrier.CarrierCMSURL)">
                                <strong>Info</strong>
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "CarrierCards",
    props: {
        carriers: {
            type: Array,
            default: () => [],
        }
    },
    methods: {
        imageUrl: function(carrier) {
            return carrier.CarrierLogoURL || '/storage/cms_images/16x9-placeholder_wvC2Ck.jpg'
        },
        clicked: function(href) {
            if (href.indexOf('http') > -1) {
                if (href.indexOf('quility.com') > -1) {
                    document.location = href
                } else {
                    window.open(href, '_balnk');
                }
            } else {
                this.$router.push(href)
            }
        }
    }
}

</script>
