<template>
	<div>
		<QAgentList
			v-model="searchAgent"
			:filters="filters"
			:label="'Search for a ' + pool + ' Certified Agent'"
			placeholder="Start typing to Search for an agent"
		/>

		<div v-if="searchAgent" class="d-flex align-start">
			<QUrlInput
				v-model="calendarURL"
				:isValid.sync="isLinkValid"
				label="Add Calendar URL"
				:loading="loading || agentCalendarLinks.loading"
				:disabled="!!duplicateAgentErrorMessage"
				:error-messages="duplicatesErrorMessage"
				requiredMessage="Calendar URL is required"
				invalidLinkMessage="This does not appear to be a valid URL. This may cause problems when redirecting to this link."
				class="url-input"
			/>
			
			<v-btn
				class="ml-4 mt-3"
				:disabled="isAddCalendarURLButtonDisabled"
				@click="$emit('add-link', calendarURL, searchAgent)"
			>
				Add
			</v-btn>
		</div>
	</div>
</template>

<script>
import QAgentList from '@/components/utils/QAgentList.vue'
import QUrlInput from '@/components/utils/QUrlInput.vue'
import QButton from '@/modules/shared/components/QButton.vue'

export default {
    name: "QAgentCalendarLinkForm",
    components: {
        QAgentList, QUrlInput, QButton,
    },
	props: {
		agentCalendarLinks: { type: Object, required: true },
		filters: { type: Object, required: true },
		calendarAgentCode: String,
		calendarUrlKey: String,
		pool: String,
		loading: Boolean,
	},
    data () {
        return {
            searchAgent: null,
            calendarURL: null,
            isLinkValid: false,
        }
    },
    computed: {
        isAddCalendarURLButtonDisabled () {
            return !this.calendarURL ||
				this.agentCalendarLinks.loading || this.agentCalendarLinks.error ||
				!this.isLinkValid || !!this.duplicatesErrorMessage ||
				this.loading
        },
		duplicateAgentErrorMessage () {
			if (!this.agentCalendarLinks.items || !this.searchAgent) { return '' }
			const duplicate = this.agentCalendarLinks.items.find(item => this.searchAgent.AgentCode === item.Agent.AgentCode)
			return duplicate
				? 'This agent is already in the reset pool. You can edit their calendar in the table.'
				: ''
		},
		duplicateLinkErrorMessage () {
			if (!this.agentCalendarLinks.items || !this.searchAgent || !this.calendarURL) { return '' }
			const duplicate = this.agentCalendarLinks.items.find(item => this.calendarURL === item.CalendarURL)
			if (!duplicate) { return '' }
			const { AgentCode, AgentName } = duplicate.Agent
			return `This calendar link is already in the reset pool (at agent ${AgentName} - ${AgentCode}). You can edit this link or ${AgentName}'s' calendar in the table.`
		},
		duplicatesErrorMessage () {
			return this.duplicateAgentErrorMessage || this.duplicateLinkErrorMessage
		},
    },
    watch: {
        searchAgent (newV) {
            if (newV) {
                this.calendarURL = this.searchAgent[this.calendarUrlKey] || this.searchAgent.DFLCalendarUrl
            } else {
				this.calendarURL = null
			}
        },
        pool () {
            this.searchAgent = null
        },
        calendarAgentCode () {
            this.searchAgent = null
        },
    },
}
</script>

<style lang="scss" scoped>
.url-input {
	width: 100%;

	&.v-input--is-disabled {
		::v-deep .v-messages {
			color: #ff5252 !important;
		}
	}
}
</style>
