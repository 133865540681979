<template>
    <v-container fluid class="grey lighten-5 pa-0">
        <QAgencyBuildingSubmenu />

        <v-row justify="space-between" align="stretch" class="mx-5" id="EverPlans">
            <v-col cols="12">
                <h2>My Reset Agent Pool</h2>
                <v-row>
                    <v-col cols="12" md="4">
						<v-select label="Reset Type" v-model="pool" :items="poolOptions" :messages="resetTypeDefinition" />
					</v-col>
                    <v-col cols="12" md="8" lg="6">
						<QAgentCalendarLinkForm
							:agent-calendar-links="agentCalendarLinks"
							:pool="pool"
							:filters="filters"
							:calendar-agent-code="agent.AgentCode"
							:calendar-url-key="calendarUrlKey"
							:loading="dataSaving"
							@add-link="addCalendarLink"
						/>
					</v-col>
					<v-col cols="12" md="auto">
						<QButton
							class="mt-3"
							:disabled="isFindAgentsDialogButtonDisabled"
							@click="showFindAgentsDialog"
						>
							Find Certified Agents
						</QButton>
					</v-col>
                    <v-col cols="12">
						<QAgentCalendarLinkTable
							v-model="doShowEditDialog"
							:agent-calendar-links="agentCalendarLinks"
							:certified-agents="certifiedAgentsCache[cacheKey]"
							:title="`${pool} Calendar Links`"
							:pool="pool"
							:calendar-url-key="calendarUrlKey"
							:loading="dataSaving"
							@refresh-links="loadData"
							@remove-link="removeCalendarLink"
							@update-link="updateCalendarLink"
						/>
					</v-col>
                </v-row>
            </v-col>
        </v-row>

		<v-dialog
			v-model="doShowFindAgentsDialog"
			:fullscreen="$vuetify.breakpoint.xs"
			width="100%"
			max-width="1600px"
		>
			<QFindCertifiedAgents
				v-if="doShowFindAgentsDialog"
				v-model="certifiedAgentsFilters"
				:pool="pool"
				:agent-calendar-links="agentCalendarLinks"
				:certified-agents="certifiedAgentsCache[cacheKey]"
				:calendar-url-key="calendarUrlKey"
				:saving="dataSaving"
				@close="doShowFindAgentsDialog = false"
				@add-link="addCalendarLink"
				@remove-link="removeCalendarLink"
				@update-link="updateCalendarLink"
			/>
		</v-dialog>
    </v-container>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QAgentCalendarLinkForm from '@/components/form/QAgentCalendarLinkForm.vue'
import QAgentCalendarLinkTable from '@/components/datatables/QAgentCalendarLinkTable.vue'
import QFindCertifiedAgents from '@/components/datatables/QFindCertifiedAgents.vue'
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue'
import QButton from '@/modules/shared/components/QButton.vue'

const resetTypeDefinitions = {
	'Debt Free Life': 'DFL agents have completed the DFL certification course in QuilityU',
	IUL: 'IUL agents have completed the IUL certification course in QuilityU',
	Annuity: 'Annuity agents have completed the Annuity certification course in QuilityU and have completed an in person bootcamp',
	IBC: 'IBC agents have completed the IBC certification course in QuilityU',
}

const resetPoolMap = {
	IUL: 'IUL',
	Annuity: 'Annuity',
	IBC: 'IBC',
	'Debt Free Life': 'DFL',
}

const calendarUrlKeyMap = {
	IUL: 'IULCalendarUrl',
	Annuity: 'AnnuityCalendarUrl',
	IBC: 'DFLCalendarUrl',
	'Debt Free Life': 'DFLCalendarUrl',
}

const filtersMap = {
	IUL: { Status : 'Active,Available', IULCertifiedInd : 1 },
	Annuity: { Status : 'Active,Available', AnnuitySME : 1 },
	IBC: { Status : 'Active,Available', IBCCertifiedInd : 1 },
	'Debt Free Life': { Status : 'Active,Available', DFLCertifiedInd : 1 },
}

export default {
    name: 'AgentCalendarLinks',
    components: {
        QAgentCalendarLinkForm,
        QAgentCalendarLinkTable,
        QAgencyBuildingSubmenu,
		QButton,
		QFindCertifiedAgents,
    },
    data () {
        return {
            pool: 'Debt Free Life',
			agentCalendarLinks: {
				loading: false,
				error: false,
				items: null,
			},
			dataSaving: false,
			doShowFindAgentsDialog: false,
			certifiedAgentsCache: {},
			doShowEditDialog: false,
			certifiedAgentsFilters: {
				statType: 'Baseshop',
			},
        }
    },
	created () {
		this.setNewCertifiedAgentsCache()
	},
	mounted () {
		this.loadData()
		this.loadCertifiedAgents()
	},
    computed: {
        agent () {
            return this.user.Agent
        },
		poolOptions () {
			return [
				'Debt Free Life',
				'IUL',
				'IBC',
				this.hasRole(['SuperAdmin', 'AgencyOwner']) && 'Annuity',
			].filter(Boolean)
		},
		resetTypeDefinition () {
			return resetTypeDefinitions[this.pool]
		},
        filters () {
			return { ...filtersMap[this.pool], attr: this.calendarUrlKey }
        },
		calendarUrlKey () {
			return calendarUrlKeyMap[this.pool]
		},
        isFindAgentsDialogButtonDisabled () {
            return this.doShowFindAgentsDialog ||
				this.agentCalendarLinks.loading || this.agentCalendarLinks.error ||
				this.dataSaving
        },
		cacheKey () {
			const filtersKey = Object.values(this.certifiedAgentsFilters)
				.map(item => item.replaceAll(' ', ''))
				.sort()
				.join('_')
			return `${resetPoolMap[this.pool]}_${filtersKey}`
		},
    },
    watch: {
        pool () {
			this.setNewCertifiedAgentsCache()
			this.agentCalendarLinks.items = null
            this.loadData()
			this.loadCertifiedAgents()
        },
        certifiedAgentsFilters: {
			deep: true,
			handler () {
				this.setNewCertifiedAgentsCache()
				this.loadCertifiedAgents()
			}
        },
        'agent.AgentCode' () {
			this.certifiedAgentsCache = {}
			this.setNewCertifiedAgentsCache()
			this.agentCalendarLinks.items = null
            this.loadData()
			this.loadCertifiedAgents()
        },
    },
    methods: {
		updateDataState (loading, error, links) {
			this.agentCalendarLinks = { loading, error, items: links ? [...links] : null }
		},
		async loadData () {
			this.updateDataState(true, false, this.agentCalendarLinks.items)
			try {
				const res = await QuilityAPI.getAgentConfigsAppointmentCalendar(this.agent.AgentCode, resetPoolMap[this.pool])
				this.updateDataState(false, false, res.data)
			} catch (err) {
				if (err.message === 'Aborted') { return }
				console.error('Error loading calendar links.', err)
				this.showError('Error loading calendar links.', err.message)
				this.updateDataState(false, true)
			}
        },
        async addCalendarLink (calendarURL, agent) {
			this.dataSaving = true
            const calendarData = {
                'AgentCode': agent.AgentCode,
                'CalendarURL': calendarURL,
                'Approved': false,
                'ApprovedBy': null,
                'reset_pool' : resetPoolMap[this.pool]
            }
			try {
				const res = await QuilityAPI.addAgentConfigAppointmentCalendar(this.agent.AgentCode, calendarData)
				if (!res.success) { throw new Error('Failed to add link (server response unsuccessful).') }
				await this.loadData()
				this.showAddingConfirmation(agent, calendarURL, resetPoolMap[this.pool])
			} catch (err) {
				console.error('Error adding calendar link', err)
				this.showError('Error adding calendar link.', err.message)
			} finally {
				this.dataSaving = false
			}
        },
        removeCalendarLink (item, calendarURL) {
			this.dataSaving = true
			const agent = this.agentCalendarLinks.items.find(({ Agent }) => Agent.AgentCode === item.AgentCode)
            const cfg_agent = agent.ConfigAgent ? agent.ConfigAgent.AgentCode : this.agent.AgentCode
			const agentCalendarURL = calendarURL || agent.CalendarURL
            QuilityAPI.deleteAgentConfigAppointmentCalendar(cfg_agent, { agent_code: agent.Agent.AgentCode, reset_pool : resetPoolMap[this.pool], calendar_url: agentCalendarURL })
                .then((json) => {
					this.updateDataState(false, false, json.data)
                    this.showRemovingConfirmation(item, agentCalendarURL, resetPoolMap[this.pool])
                })
                .catch((err) => {
                    console.error('Error removing calendar link.', err)
                    this.showError('Error removing calendar link.', err.message)
                })
				.finally(() => { this.dataSaving = false })
        },
        updateCalendarLink (calendarURL, item) {
            this.dataSaving = true
			const agent = this.agentCalendarLinks.items.find(({ Agent }) => Agent.AgentCode === item.AgentCode)
            const cfg_agent = agent.ConfigAgent ? agent.ConfigAgent.AgentCode : this.user.Agent.AgentCode
            const calendarData = {
                'AgentCode': agent.Agent.AgentCode,
                'CalendarURL': calendarURL,
                'Approved': false,
                'ApprovedBy': null,
                'reset_pool' : resetPoolMap[this.pool],
            }
            QuilityAPI.updateAgentConfigAppointmentCalendar(cfg_agent, calendarData)
                .then((json) => {
					this.updateDataState(false, false, json.data)
					this.doShowEditDialog = false
                    this.showUpdatingConfirmation(item, calendarURL, resetPoolMap[this.pool])
                })
                .catch((err) => {
                    console.error('Error updating calendar link.', err)
                    this.showError('Error updating calendar link.', err.message)
                })
                .finally(() => { this.dataSaving = false })
        },
		async loadCertifiedAgents () {
            const cacheKey = this.cacheKey  // this is necessary because the computed value may change during an asynchronous request
			if (this.certifiedAgentsCache[cacheKey].items || this.certifiedAgentsCache[cacheKey].loading) {
				return Promise.resolve()
			}
			this.certifiedAgentsCache[cacheKey].loading = true
			this.certifiedAgentsCache[cacheKey].error = false
			try {
				const res = await QuilityAPI.getSymmetryAgentSearch({ ...this.filters, ...this.certifiedAgentsFilters, itemsPerPage: 'all' })
				this.certifiedAgentsCache[cacheKey].items = res.data
				this.certifiedAgentsCache[cacheKey].loaded = true
				this.certifiedAgentsCache[cacheKey].loading = false
			} catch (err) {
				if (err.message === 'Aborted') { return }
				console.error('Issue with loading certified agents data', err)
				this.showError('Issue with loading certified agents data.', err.message)
				this.certifiedAgentsCache[cacheKey].loading = false
				this.certifiedAgentsCache[cacheKey].error = true
			}
		},
		showAddingConfirmation (agent, calendarURL, resetPool) {
			this.showSuccess(`Agent <strong>${agent.AgentName}</strong>'s (${agent.AgentCode}) calendar URL <strong>${calendarURL}</strong> has been added to your <strong>${resetPool}</strong> reset pool.`)
		},
		showRemovingConfirmation (agent, calendarURL, resetPool) {
			this.showSuccess(`Agent <strong>${agent.AgentName}</strong>'s (${agent.AgentCode}) calendar URL <strong>${calendarURL}</strong> has been removed from your <strong>${resetPool}</strong> reset pool.`)
		},
		showUpdatingConfirmation (agent, calendarURL, resetPool) {
			this.showSuccess(`Agent <strong>${agent.AgentName}</strong>'s (${agent.AgentCode}) calendar URL has been updated in your <strong>${resetPool}</strong> reset pool. New calendar URL is <strong>${calendarURL}</strong>.`)
		},
		showFindAgentsDialog () {
            this.certifiedAgentsFilters = {
                statType: 'Baseshop',
            }
			this.doShowFindAgentsDialog = true
		},
		setNewCertifiedAgentsCache () {
			if (this.certifiedAgentsCache[this.cacheKey]) { return }
			this.$set(this.certifiedAgentsCache, this.cacheKey, { loading: false, loaded: false, error: false, items: null })
		},
    },
}
</script>

<style scoped>
::v-deep .v-messages__message {
	line-height: 1.3;
}
</style>
